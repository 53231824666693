var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { class: _vm.wrapperClass },
    [
      _vm.showHeader ? _c("Header") : _vm._e(),
      _vm.user.information ? _c("OutOfOffice") : _vm._e(),
      _c("ErrorValidations"),
      _vm.user.information
        ? _c(
            "div",
            { attrs: { id: "wrapper" } },
            [
              _c("TimeOut"),
              !_vm.showFullscreen
                ? _c("Leftbar", { on: { clickedMenu: _vm.clickedMenu } })
                : _vm._e(),
              _c(
                "div",
                {
                  class: { transparent: _vm.isTransparent },
                  attrs: { id: "page-content" },
                },
                [
                  !_vm.showFullscreen
                    ? _c("b-breadcrumb", {
                        attrs: { items: _vm.crumbs },
                        on: { click: _vm.gotoLink },
                      })
                    : _vm._e(),
                  _c("router-view", { attrs: { id: "main-content" } }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.user.information
        ? _c(
            "div",
            { attrs: { id: "wrapper" } },
            [
              _c(
                "div",
                { attrs: { id: "page-content" } },
                [
                  _c("router-view", {
                    key: _vm.appKey,
                    attrs: { id: "main-content" },
                  }),
                ],
                1
              ),
              _vm.showFooter ? _c("Footer") : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }