<template>
  <section
    v-bind:class="wrapperClass">
    <Header v-if="showHeader"></Header>
    <OutOfOffice v-if="user.information"></OutOfOffice>     
    <ErrorValidations></ErrorValidations>
    <div id="wrapper" v-if="user.information"> 
      <TimeOut></TimeOut>
      <Leftbar v-if="!showFullscreen" @clickedMenu="clickedMenu"></Leftbar>
      <div id="page-content" v-bind:class="{ transparent: isTransparent }">
          <b-breadcrumb :items="crumbs" @click="gotoLink" v-if="!showFullscreen"></b-breadcrumb>
          <router-view id="main-content"></router-view>
      </div>
    </div>
    <div id="wrapper" v-if="!user.information"> 
      <div id="page-content">
        <router-view :key="appKey" id="main-content"></router-view>
      </div>
      <Footer v-if="showFooter"></Footer>
    </div>    
  </section>
</template>
<script>
import Footer from "@/components/Layout/Footer";
import Header from "@/components/Layout/Header";
import Leftbar from "@/components/Layout/Leftbar";
import TimeOut from "@/components/TimeOut";
import OutOfOffice from "@/components/OutOfOffice";
import ErrorValidations from "@/components/ErrorValidations";
export default {
  components: { Header, Footer, Leftbar, TimeOut, ErrorValidations, OutOfOffice },
  head: {
    title: 'Home page'
  },
  data() {
    return {
      appIndex: 0
    };
  },
  methods: {
    clickedMenu(){
    },  
    gotoLink(){
      this.appIndex++;
    },
    async init() {
      //await this.$store.dispatch("user/fetchVersion");
    }
  },
  computed: {
    appKey(){
      return "appKey" + this.appIndex;
    },
    wrapperClass() {
      return this.user.information == null ? 'not-authenticated' : 'authenticated';
    },
    isTransparent() {   
      return (this.$route.name == "Dashboard") || (this.$route.name == "Support");
    },
    showHeader() {
      return this.$route.meta.showHeader;
    },
    showFooter() {
      return this.$route.meta.showFooter;
    },
    showFullscreen() {
      return this.$route.meta.showFullscreen;
    },
    crumbs: function() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {                
        if(this.$route.matched[idx] != undefined)
        {
          if(this.$route.matched[idx].meta != undefined)
          {
            if(this.$route.matched[idx].meta.breadCrumb != undefined)
            {
              if(this.$route.matched[idx].meta.breadCrumb != "")
              {
                breadcrumbArray.push({
                  to: { name: this.$route.matched[idx].name },
                  text: this.$t(this.$route.matched[idx].meta.breadCrumb),
                });
              }
            }
          }
        }
        return breadcrumbArray;
      }, []);
      if(this.$route.meta.crumbParam != undefined)
        {
          for (var key of Object.keys(this.$route.params)) {
            if(this.$route.meta.crumbParam == key)
            {
              breadcrumbs.push({
                path: '#',
                text: this.$route.params[key],
              });
            }
          }
        }
      return breadcrumbs;
    }
  },
  watch: { 
    sefos_locale: function(newVal, oldVal) {
      if(newVal != oldVal)
      {
        this.$i18n.locale = newVal;        
        let h = document.querySelector('html');
        h.setAttribute('lang', newVal);
      }      
    }
  },
  mounted() {
    console.log("Mounted App");
    this.init();
  },
};
</script>
<style></style>